import React, { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/icons/Logo.svg';
import styles from './header.module.scss';
import { PaymentModalStatuses } from '../../utils/enums';
import { trackGaEvent } from '../../utils/trackGaEvent';

type HeaderProps = {
  title: string;
  buttonTitle: string;
  documentName: string | undefined;
  isEditorVisible: boolean;
  paymentModalStatus: PaymentModalStatuses;
  setPaymentModalStatus: (status: PaymentModalStatuses) => void;
};

const Header: FC<HeaderProps> = ({
  buttonTitle,
  isEditorVisible,
  setPaymentModalStatus,
  paymentModalStatus,
  documentName,
}) => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const id = location.pathname.substring(1);
  const isContactPage = location.pathname === '/contact-us';
  const isSpecialPage =
    location.pathname === '/contact-us' || location.pathname === '/terms' || location.pathname === '/privacy-policy';

  const handleButtonClick = () => {
    setIsActive(!isActive);

    if (!id) {
      return;
    }

    trackGaEvent('landing_contact_us_click', { form_name: id });
  };

  const handleOpenModal = () => {
    setPaymentModalStatus(PaymentModalStatuses.Opened);

    if (!id) {
      return;
    }

    trackGaEvent('landing_done_click', { form_name: id });
  };

  return (
    <div className={`${styles.header} ${isSpecialPage ? styles.shadow : ''}`}>
      <Link to={`/${documentName}`}>
        <img src={logo} alt='Logo' />
      </Link>
      {!isEditorVisible || isSpecialPage ? (
        <div className={`${styles.button_contact} ${isContactPage ? styles.active : ''}`}>
          <Link to='/contact-us' onClick={handleButtonClick}>
            {buttonTitle}
          </Link>
        </div>
      ) : (
        <button
          onClick={handleOpenModal}
          className={`${styles.btn_Done} ${paymentModalStatus === PaymentModalStatuses.Done ? styles.disabled : ''}`}
          disabled={paymentModalStatus === PaymentModalStatuses.Done}
        >
          Done
        </button>
      )}
    </div>
  );
};

export default Header;
