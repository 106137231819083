import axios from '../api/api';
import { MAIL_CONTACT_US, MAIL_SEND_PDF, PAYMENT_FORM } from './endpoints';
import axiosIP from 'axios';

interface ContactFormData {
  email: string;
  message: string;
}

export const sendEmailWithPdf = async (formData: FormData) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await axios.post(MAIL_SEND_PDF, formData, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const sendContactFormData = async (data: ContactFormData) => {
  try {
    const response = await axios.post(MAIL_CONTACT_US, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPaymentConfig = async (userIP: string) => {
  try {
    const response = await axios.post(PAYMENT_FORM, { ip: userIP });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUserIP = async () => {
  try {
    const response = await axiosIP.get('https://api.ipify.org/?format=json');
    const publicIp = response.data.ip;
    return publicIp;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
