import React, { useState } from 'react';
import styles from './contact.module.scss';
import { sendContactFormData } from '../../api/requests';
import Footer from '../Footer/Footer';

interface ContactFormData {
  email: string;
  message: string;
}

const ContactUs = () => {
  const [formData, setFormData] = useState<ContactFormData>({ email: '', message: '' });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await sendContactFormData(formData);
    setFormData({ email: '', message: '' });
  };
  return (
    <>
      <div className={styles.contactPage}>
        <h1>Need help?</h1>
        <p>Tell us all the details on your issue, and we will fix it ASAP.</p>
        <form onSubmit={handleSubmit}>
          <label htmlFor='email'>Email address:</label>
          <input
            className={styles.emailInput}
            placeholder='youremailaddress@email.com'
            type='email'
            id='email'
            name='email'
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <label htmlFor='message'>Your message:</label>
          <textarea
            id='message'
            name='message'
            placeholder='Please describe your problem here'
            className={styles.messageInput}
            value={formData.message}
            onChange={handleInputChange}
            required
          />
          <button type='submit'>Send message</button>
        </form>
      </div>
      <Footer />
    </>
  );
};
export default ContactUs;
