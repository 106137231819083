import React, { FC } from 'react';
import styles from './StatusModal.module.scss';
import successIcon from '../../assets/icons/success-icon.svg';
import errorIcon from '../../assets/icons/error-icon.svg';
import { Link } from 'react-router-dom';
import { PaymentModalStatuses } from '../../utils/enums';
import { PaymentData } from '../../utils/interfaces';

type StatusModal = {
  isSuccess: boolean;
  setPaymentModalStatus: (status: PaymentModalStatuses) => void;
  setPaymentData: (value: PaymentData | null) => void;
};

const StatusModal: FC<StatusModal> = ({ isSuccess, setPaymentModalStatus, setPaymentData }) => {
  const icon = isSuccess ? successIcon : errorIcon;
  const title = isSuccess
    ? `Payment Successful! </br> Your PDF form download will start soon...`
    : 'Oops, something went wrong with this payment';
  const description = isSuccess
    ? 'We also send this form to your email- please check the inbox and Spam folders. If you need help, dont hesitate to'
    : 'Please try one more time or use another card.';

  const handleCloseModal = () => {
    setPaymentModalStatus(PaymentModalStatuses.Done);
  };
  const handleTryAgain = (event: React.MouseEvent<HTMLElement>) => {
    setPaymentData(null);
    event.stopPropagation();
    setPaymentModalStatus(PaymentModalStatuses.Opened);
  };
  return (
    <div className={styles.overlay} onClick={handleCloseModal}>
      <div className={styles.modal}>
        <div className={styles.header} style={{ backgroundColor: isSuccess ? '#3CD770' : '#F14C4C' }}>
          <div className={`${styles.status} ${isSuccess ? styles.success : styles.error}`}>
            <img className={styles.icon} src={icon} alt='' />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></div>
          <div className={styles.description}>{description}</div>
          <div className={styles.buttonContainer}>
            {isSuccess ? (
              <Link to='/contact-us' className={styles.button}>
                contact us
              </Link>
            ) : (
              <button type='button' className={styles.failButton} onClick={handleTryAgain}>
                Try Again
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
