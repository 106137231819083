import React from 'react';
import styles from './PolicyPage.module.scss';

const PolicyPage = () => {
  return (
    <div className={styles.container}>
      <h1>Privacy Policy</h1>

      <p>
        This Privacy Policy governs your use of askuora.com (the “Website”) and describes the data we collect, how it is
        stored, how that data may be used, with whom it may be shared, and your choices about such uses and disclosures.
        Please be sure to carefully read the entirety of this Privacy Policy when using our Website.
      </p>

      <p>
        &ldquo;<span>GDPR</span>&rdquo; means the General Data Protection Regulation (EU) 2016/679 of the European
        Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the
        processing of personal data and on the free movement of such data.
      </p>

      <p>
        &ldquo;<span>EEA</span>&rdquo; includes all current Member States to the European Union and the European
        Economic Area.
      </p>

      <p>
        &ldquo;<span>Process</span>&rdquo;, in respect of personal data, includes to collect, store, use, and disclose
        to others.
      </p>

      <p>
        Check Askuora Terms of Use&Service (the “Terms”) for the meaning of defined words (those with capital letters)
        not explicitly defined in this Privacy Policy.
      </p>

      <p>
        <span>1. PERSONAL DATA CONTROLLER</span>
      </p>

      <p>Lemandale Limited will be the controller of your personal data.</p>

      <p>
        <span>2. WHAT CATEGORIES OF PERSONAL DATA DO WE COLLECT?</span>
      </p>

      <p>
        We collect data you make available to us voluntarily (for example, email address, phone number, name, date of
        birth). We also collect data automatically when you use the Website (for example, your IP address, device type).
      </p>

      <p>
        <span>2.1. Data you give us</span>
      </p>

      <p>
        You provide us data about yourself when you register for and/or use the Website, for example, when you create a
        user profile (“Profile”), respond to our emails, or report a problem. The data that you give us includes:
      </p>

      <p>
        Profile data. This includes your name, username, date of birth, email address and phone number. You may also
        provide us your voice and image (in any content you upload) and additional data on your Profile.
      </p>

      <p>
        <span>2.2. Data we collect automatically:</span>
      </p>

      <p>
        <span>Data about how you found us.</span> We collect data about your referring URL (that is, the place on the
        Web where you were when you tapped on our ad).
      </p>

      <p>
        <span>Cookies and other similar technologies.</span> As further described in our Cookie Policy, our Website use
        cookies that record data about the use of our website to distinguish you from other users. You can control
        cookies through your browser settings.
      </p>

      <p>
        <span>Browser and Device data.</span> We collect data from or about the devices from which you access the
        Website, depending on the permissions you’ve granted. We may associate the data we collect from your different
        devices, which helps us provide consistent Services across your devices Examples of such data include: language
        settings, IP address, location, time zone, type and model of a device, device settings, operating system,
        Internet service provider, mobile carrier, hardware ID, and Facebook ID.
      </p>

      <p>
        We also collect your Apple Identifier for Advertising (“IDFA”) or Google Advertising ID (“AAID”) (depending on
        the operating system of your device). You can typically reset these numbers through the settings of your
        device’s operating system (but we do not control this).
      </p>

      <p>
        <span>Transaction data.</span> When you make payments through the Website, you need to provide financial account
        data, such as your credit card number, to our third-party service providers. We do not collect or store full
        credit card number data, though we may receive credit card-related data and data about the transaction,
        including: date, time and amount of the transaction, and the type of payment method used.
      </p>

      <p>
        <span>Usage data.</span> We record how you interact with our Website. For example, we log the features, and
        content you interact with, how often and long you use the Website, what sections you use, and other
        interactions.
      </p>

      <p>
        <span>3. FOR WHAT PURPOSES DO WE PROCESS YOUR PERSONAL DATA?</span>
      </p>

      <p>We process your personal data:</p>

      <p>
        <span>3.1. to provide services to you.</span>
      </p>
      <p>
        This includes enabling you to use the Website in a seamless manner and preventing or addressing Website errors
        or technical issues. For example, we use your data to authenticate you and authorize access to our Website.
      </p>

      <p>
        <span>3.2. to research and analyze your use of the Website</span>
      </p>

      <p>
        This helps us to better understand our business, analyze our operations, maintain, improve, innovate, plan,
        design, and develop the Website and our new products. We conduct surveys, research, and test features in
        development. We analyze the data we have to evaluate our Website, and conduct audits and troubleshooting
        activities to improve our Website content and layouts. As a consequence, we often decide how to improve the
        Website based on the results obtained from this processing.
      </p>

      <p>
        <span>3.3. to customize the Website for you</span>
      </p>

      <p>
        We select the payment processor available to you; we may also use your data to determine your eligibility for
        promotions, sweepstakes, and contests.
      </p>

      <p>
        <span>3.4. to process your payments</span>
      </p>

      <p>
        We provide paid products and/or services within the Website. For this purpose, we use third-party services for
        payment processing (for example, payment processors). As a result of this processing, you will be able to make a
        payment for paid features of the Website.
      </p>
      <p>
        <span>3.5. to enforce the Terms</span> and to prevent, detect, investigate, and resolve disputes and/or
        remediate malicious activities, fraud, other cybercrimes, take legally-required actions, or make sure you are a
        real person and avoid the creation of fake accounts.
      </p>

      <p>
        We use personal data to enforce our agreements and contractual commitments, to detect, prevent, and combat
        fraud. As a result of such processing, we may share your information with others, including law enforcement
        agencies (in particular, if a dispute arises in connection with the Terms).
      </p>

      <p>
        <span>3.6. to communicate with you regarding your use of our Website </span>
      </p>

      <p>
        We may communicate with you, for example, by email or directly on the Website, including through push
        notifications. As a result of such processing, we may send you messages about your statistics, such as who visit
        your Profile, add you to favorites list, like you or send you a message.
      </p>

      <p>
        <span>3.7. to show and/or send you marketing communications</span>
      </p>

      <p>
        We process your personal data for our marketing campaigns. We may add your email address to our marketing list.
        As a result, you will receive information about our Website, features, offers, promotions, contests, and events
        or provide other news or information about third party services that may be of interest to you. You can opt out
        of advertisement and/or commercial emails by clicking here: unsubscribe
      </p>

      <p>
        <span>3.8. to provide you customer service and support</span>
      </p>

      <p>
        AAs a result of such processing, we will send you messages about the availability of our Website security,
        payment transactions, status of your orders, legal notices, or other Website-related information.
      </p>

      <p>
        <span>3.9. to personalize our ads</span>
      </p>

      <p>
        We and our partners use your personal data to tailor ads and possibly even show them to you at the relevant
        time.
      </p>

      <p>
        <span>3.10. to comply with legal obligations</span>
      </p>

      <p>
        We may process, use, or share your data when the law requires it, in particular, if a law enforcement agency
        requests your data by available legal means.
      </p>

      <p>
        <span>4. LEGAL BASIS FOR DATA PROCESSING (EEA ONLY)</span>
      </p>

      <p>
        In this section, we are letting you know what legal basis we use for each particular purpose of processing. For
        more information on a particular purpose, please refer to Section 2. This section applies only to EEA-based
        users.
      </p>

      <p>We process your personal data under the following legal grounds:</p>

      <p>
        <span>4.1. your consent;</span>
      </p>

      <p>
        <span>4.2. to perform our contract with you;</span>
      </p>

      <p>Under this legal basis we:</p>

      <p>&bull; Provide the services</p>
      <p>&bull; Customize your experience</p>
      <p>&bull; Communicate with you regarding your use of the Website</p>
      <p>&bull; Manage your account and provide you with customer support</p>
      <p>&bull; Provide you customer service and support</p>
      <p>&bull; Process your payments</p>

      <p>
        <span>4.3. </span> for our (or others) legitimate interests, unless those interests are overridden by your
        interests or fundamental rights and freedoms that require protection of personal data;
      </p>

      <p>We rely on legitimate interests:</p>

      <p>&bull; to communicate with you regarding your use of our Website</p>
      <p>
        The legitimate interest we rely on for this purpose is our interest to encourage you to use our Website more
        often. We also take into account the potential benefits to you.
      </p>

      <p>&bull; to research and analyze your use of the Website</p>
      <p>
        Our legitimate interest for this purpose is our interest in improving our Website so that we understand users’
        preferences and are able to provide you with a better experience (for example, to make the use of the Website
        easier and more enjoyable, or to introduce and test new features).
      </p>

      <p>&bull; to send you marketing communications</p>
      <p>
        The legitimate interest we rely on for this processing is our interest to promote our Website in a measured and
        appropriate way.
      </p>

      <p>&bull; to personalize our ads</p>
      <p>
        The legitimate interest we rely on for this processing is our interest to promote our Website in a reasonably
        targeted way.
      </p>

      <p>&bull; to enforce the Terms and to prevent and combat fraud</p>
      <p>
        Our legitimate interests for this purpose are enforcing our legal rights, preventing and addressing fraud and
        unauthorized use of the Website, non-compliance with the Terms.
      </p>

      <p>
        <span>4.4.</span> to comply with legal obligations. Under this legal basis we, in particular, verify your
        identity.
      </p>

      <p>
        <span>CALIFORNIA PRIVACY RIGHTS</span>
      </p>

      <p>
        This section provides additional details about how we process personal data of California consumers and the
        rights available to them under the California Consumer Privacy Act (“CCPA”) and California’s Shine the Light
        law. Therefore, this section applies only to residents of California, United States.
      </p>

      <p>
        For more details about the personal information we have collected, including the categories of sources, please
        see Section 3 above. We collect this information for purposes described in Section 4 this Privacy Policy. We may
        also share your information with certain categories of third parties as indicated in Section 10. We do not sell
        (as such term is defined in the CCPA) the personal information we collect.
      </p>

      <p>
        Subject to certain limitations, the CCPA provides California consumers the right to request to know more details
        about the categories or specific pieces of personal information we collect (including how we use and disclose
        this information), to delete their personal information, to opt-out of any “sales” that may be occurring, and to
        not be discriminated against for exercising these rights.
      </p>

      <p>
        California consumers may make a request pursuant to their rights under the CCPA by contacting us at
        pdfforms@askuora.com. We will verify your request and inform you accordingly. You may also designate an
        authorized agent to exercise these rights on your behalf.
      </p>

      <p>Access rights under California’s Shine the Light</p>

      <p>
        California also provides its residents with additional access rights. Under Shine the Light law, the residents
        the right to ask companies once a year what personal information they share with third parties for those third
        parties direct marketing purposes. Learn more about what is considered to be personal information under the
        statute.
      </p>

      <p>
        To obtain this information from us, please send an email message to us pdfforms@askuora.com, which includes
        “Request for California Shine the Light Privacy Information” on the subject line and your state of residence and
        email address in the body of your message. Please be aware that not all information sharing is covered by the
        “Shine the Light” requirements and only information on covered sharing will be included in our response.
      </p>

      <p>
        <span>6. DATA RETENTION</span>
      </p>

      <p>
        We will retain personal data for the period necessary to fulfill the purposes outlined in this Privacy Policy
        unless a longer retention period is required or permitted by law.
      </p>

      <p>
        Please note that we have a variety of obligations to retain the data that you provide to us, including to ensure
        that transactions can be appropriately processed, settled, refunded, or charged-back, to help identify fraud and
        to comply with anti-money laundering and other laws and rules that apply to us and to our financial service
        providers. Accordingly, even if you disable/delete your Profile, we will retain certain data to meet our
        obligations.
      </p>

      <p>
        <span>7. WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</span>
      </p>

      <p>
        We share information with third parties that help us operate, provide, improve, integrate, customize, support,
        and market our Service. We may share some sets of personal data, in particular, for purposes indicated in
        Section 3 of this Privacy Policy. The types of third parties we share information with include, in particular:
      </p>

      <p>
        <span>7.1. Service providers</span>
      </p>

      <p>
        We share personal data with third parties that we hire to provide services or perform business functions on our
        behalf, based on our instructions. We may share your personal information with the following types of service
        providers:
      </p>

      <p>&bull; cloud storage providers (Google Cloud Platform, servers.com);</p>
      <p>&bull; data analytics providers (Facebook, Google, Amplitude);</p>
      <p>
        &bull; marketing partners (in particular, social media networks, marketing agencies, email delivery services);
      </p>
      <p>&bull; payment processing contractors.</p>

      <p>
        <span>7.2. Law enforcement agencies and other public authorities</span>
      </p>
      <p>
        We may use and disclose personal data to enforce the Terms, to protect our rights, privacy, safety, or property,
        and/or that of our affiliates, you or others, and to respond to requests from courts, law enforcement agencies,
        regulatory agencies, and other public and government authorities, or in other cases provided for by law.
      </p>

      <p>
        <span>7.3. Third parties as part of a merger or acquisition</span>
      </p>

      <p>
        As we develop our business, we may buy or sell assets or business offerings. Customers’ information is generally
        one of the transferred business assets in these types of transactions. We may also share such information with
        any affiliated entity (e.g. parent company or subsidiary) and may transfer such information in the course of a
        corporate transaction, such as the sale of our business, a divestiture, merger, consolidation, or asset sale, or
        in the unlikely event of bankruptcy.
      </p>

      <p>
        <span>8. INTERNATIONAL DATA TRANSFERS</span>
      </p>

      <p>
        We may transfer personal data to countries other than the country in which the data was originally collected in
        order to provide the Website set forth in the Terms and for purposes indicated in this Privacy Policy. If these
        countries do not have the same data protection laws as the country in which you initially provided the
        information, we deploy special safeguards.
      </p>

      <p>
        In particular, if we transfer personal data originating from the EEA to countries with not adequate level of
        data protection, we use one of the following legal bases: (i) Standard Contractual Clauses approved by the
        European Commission (details available here), or (ii) the EU-U.S. Privacy Shield Framework (details available
        here), or (iii) the European Commission adequacy decisions about certain countries (details available here).
      </p>

      <p>
        <span>9. CHANGES TO THIS PRIVACY POLICY</span>
      </p>

      <p>
        We may modify this Privacy Policy at any time. If we decide to make material changes to this Privacy Policy, you
        will be notified through our Service or by other available means and will have an opportunity to review the
        revised Privacy Policy. By continuing to access or use the Website after those changes become effective, you
        agree to be bound by the revised Privacy Policy.
      </p>

      <p>
        <span>10. AGE LIMITATION</span>
      </p>

      <p>
        We do not knowingly process personal data from persons under 18 years of age. If you learn that anyone younger
        than 18 has provided us with personal data, please contact us at pdfforms@askuora.com.
      </p>

      <p>
        <span>11. PRIVACY RIGHTS</span>
      </p>

      <p>To be in control of your personal data, you have the following rights:</p>

      <p>
        Accessing / reviewing / updating / correcting your personal data. You may review, edit, or change the personal
        data that you had previously provided to us. You control your Profile; thus, you may access your profile data at
        any time and to correct or update it at any time by logging in to the Website. You may also request a copy of
        your personal data collected during your use of the Website at pdfforms@askuora.com.
      </p>

      <p>
        Deleting your personal data. You can request erasure of your personal data by sending us an email at
        pdfforms@askuora.com.
      </p>

      <p>
        When you request deletion of your personal data, we will use reasonable efforts to honor your request. In some
        cases, we may be legally required to keep some of the data for a certain time; in such event, we will fulfill
        your request after we have complied with our obligations.
      </p>

      <p>
        Objecting to or restricting the use of your personal data. You can ask us to stop using all or some of your
        personal data or limit our use thereof by sending a request at pdfforms@askuora.com.
      </p>

      <p>
        The right to lodge a complaint with supervisory authority. We would love you to contact us directly, so we could
        address your concerns. Nevertheless, you have the right to lodge a complaint with a competent data protection
        supervisory authority, in particular in the EU Member State where you reside, work or where the alleged
        infringement has taken place.
      </p>

      <p>
        The right to data portability. If you wish to receive your personal data in a machine-readable format, you can
        send respective request at pdfforms@askuora.com.
      </p>

      <p>
        You may submit requests through an authorized agent, in which case we will need to verify the agent’s identity,
        your identity, and their authority to act on your behalf before we can process the request.
      </p>

      <p>
        <span>12. HOW DO WE HANDLE “DO NOT TRACK” REQUESTS?</span>
      </p>

      <p>
        Except as otherwise stipulated in this Privacy Policy, the Website does not support “Do Not Track” requests. To
        determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their
        privacy policies.
      </p>

      <p>
        <span>13. CONTACT US</span>
      </p>

      <p>
        If you have any questions or concerns about this Privacy Policy or our collection, use, or storage or your data,
        please do not hesitate to contact us at:
      </p>

      <p>
        <span>E-mail:</span> pdfforms@askuora.com.
      </p>
    </div>
  );
};

export default PolicyPage;
