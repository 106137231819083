import React, { FC, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import styles from './overlay.module.scss';
import useWindowSize from '../../hooks/useWindowSize';
import { useParams } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { trackGaEvent } from '../../utils/trackGaEvent';

type OverlayProps = {
  handleDownload: () => void;
};
const Overlay: FC<OverlayProps> = ({ handleDownload }) => {
  const { id } = useParams();
  const { windowHeight, windowWidth } = useWindowSize();
  const fileName = id?.replace('.pdf', '');
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      return;
    }

    if (!id) {
      return;
    }

    trackGaEvent('landing_page_view', {
      form_name: id,
    });
    isFirstRender.current = false;
  }, [id]);

  return (
    <>
      <div className={styles.overlay}>
        <div className={styles.container}>
          <h1>Get the most current {id} form now</h1>
          <button onClick={handleDownload}>Get my form &#62;</button>
        </div>
        <div className={styles.disclaimerGroup}>
          <ul>
            <li>
              askuora.com is a privately owned website that is not affiliated, owned or operated by IRS, any government
              agency, or any other authority. It offers a user-friendly form that can be filled out in preparation for
              the corresponding action.
            </li>
            <li>
              Lemandale Limited does not guarantee the accuracy, correctness, completeness, or reliability of any
              Content and assumes no responsibility in this regard.
            </li>
            <li>
              Lemandale Limited does not provide any advice or professional services to users, nor does it act as an
              agent for any customer. Users are responsible for submitting the form and paying the appropriate fees to
              the authorities themselves.
            </li>
          </ul>
        </div>
      </div>

      <Document
        file={`pdf/${fileName}.pdf`}
        options={{ workerSrc: '/pdf.worker.js' }}
        className={styles.previewContainer}
      >
        {windowHeight && windowWidth && (
          <div style={{ height: windowHeight - 72 + 'px', overflowY: 'hidden' }}>
            <Page scale={1.5} pageNumber={1} renderAnnotationLayer={false} renderTextLayer={false} />
          </div>
        )}
      </Document>
      <Footer />
    </>
  );
};

export default Overlay;
