import React, { useEffect, useRef, FC, useState, useCallback } from 'react';
import PSPDFKit, { Instance } from 'pspdfkit';
import { useParams } from 'react-router-dom';
import Modal from '../Modal/Modal';
import SolidGateForm, { OrderStatusMessage } from '@solidgate/react-sdk';
import { getPaymentConfig, getUserIP, sendEmailWithPdf } from '../../api/requests';
import StatusModal from '../StatusModal/StatusModal';
import { PaymentModalStatuses } from '../../utils/enums';
import { PaymentData } from '../../utils/interfaces';
import styles from './PdfViewerComponent.module.scss';
import { Link } from 'react-router-dom';
import { PDF_PRICE } from '../../utils/constants';
import { trackGaEvent } from '../../utils/trackGaEvent';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const PSPDFKIT_LICESNE_KEY =
  'bAcyQP6iuyxkY6l4-_5IZuUC4t8jSfJmrEVCs4jKexX6XAFxRqiQFlVVUtU3NJpnjt24g_5v0elueXIZDVqgtm2UzthuoCuw3D2WmK11T7uQJ0Ou50Ez53Zc6OBdOc0Gtp80Cr2nvm5NW82-YrGxYmt3iIbuotSTtRuG2EY6X-mMOJUbH1vSoIWhNUweaBGLXE4B_-MYADAf4innJE3fzdk28fvFsy79xf0xN5mYns_yvoSPUux-pfhk7Qhpo-s1u239-6BbRkbe5KXbv83h06yCPeb3SFWUw5vO-OrYT6N1MmIw__YYTwBAzy0JO9tVKV2db0EUiY5_z5kNU88DCOd-65fApRWg1e4fjdIlhpezreYcb7jfdBRMPGgn4rEmyPS80DGtMWJ0XUe6No-5eNvZmCz-eWq8viU8eScJieVcgoc32g46FwJMAImDNclpEmr79uAqFTUgdoS_8rWOZ3X-Hpj1vPBDZi6JLaii8Dwx4c8nLd0xTxr9DDxMLu-TluKowOiX-qwmtBaqGw8WwTetf8Q05yNggbXpl1EXTJJV4gwTBn7SgCWTE8kPHFCYRQx4z1-USeTc59qa4p631hJ50QWuo8W2oUhgF-k7_SYXyJOjeeuD9dMRMlrPn4nSCB1tBM1bXG9zvNflGb3ZQxbejJbulr639LuFNmtmDdo=';

type PdfViewerComponentProps = {
  paymentModalStatus: PaymentModalStatuses;
  setPaymentModalStatus: (status: PaymentModalStatuses) => void;
};

const PdfViewerComponent: FC<PdfViewerComponentProps> = ({ paymentModalStatus, setPaymentModalStatus }) => {
  const { id } = useParams();
  const [payment, setPaymentData] = useState<PaymentData | null>(null);
  const [userIP, setUserIP] = useState<string>();
  const [isPaymentModalRendered, setPaymentModalRendered] = useState(false);
  const instanceRef = useRef<Instance | null>(null);
  const containerRef = useRef(null);
  const setupUserIP = async () => {
    const userIp = await getUserIP();
    setUserIP(userIp);
  };
  const fileName = id?.replace('.pdf', '');

  useEffect(() => {
    setupUserIP();
    setPaymentModalStatus(PaymentModalStatuses.Closed);
  }, []);

  const trackConversion = () => {
    trackGaEvent('conversion', { send_to: 'AW-10792715563/mV_UCLjbp5wYEKuKr5oo', transaction_id: '' });

    if (!id) {
      return;
    }

    trackGaEvent('payment_page_payment_click_success', { form_name: id, type: 'card' });
  };

  const handlePayment = async (userIP: string) => {
    const result = await getPaymentConfig(userIP);

    setPaymentData({
      paymentIntent: result.paymentIntent,
      signature: result.signature,
      merchant: result.merchant,
    });
  };

  const addExportButton = () => {
    if (!instanceRef.current) return;
    const items = instanceRef.current.toolbarItems;
    instanceRef.current.setToolbarItems([
      ...items,
      { type: 'print' },
      { type: 'document-editor' },
      { type: 'document-crop' },
      { type: 'export-pdf' },
    ]);
  };

  const handleSubmit = () => {
    if (!id) {
      return;
    }

    trackGaEvent('payment_page_payment_init', { form_name: id });
  };

  const handleCloseModal = () => {
    setPaymentModalStatus(PaymentModalStatuses.Closed);
    setPaymentModalRendered(false);

    if (!id) {
      return;
    }

    trackGaEvent('payment_page_close_pop_up_button_click', { form_name: id });
  };

  const trackAnnotationClick = useCallback(() => {
    if (!id) {
      return;
    }

    trackGaEvent('landing_field_in_file_select', { form_name: id });
  }, [id]);

  const renderModal = () => {
    if (!payment) return;
    switch (paymentModalStatus) {
      case PaymentModalStatuses.Opened:
        return (
          <Modal>
            {isPaymentModalRendered && (
              <div className={styles.closeModalButton} onClick={handleCloseModal}>
                &times;
              </div>
            )}

            <SolidGateForm
              width='100%'
              styles={{
                form_body: {
                  width: '100%',
                  'max-width': '524px',
                  margin: 'auto',
                  padding: '40px 52px 10px',
                  'font-family': "'Poppins', sans-serif",
                  'background-color': '#ffffff',
                  'border-radius': '8px',
                },
                header: {
                  'font-family': "'Montserrat', sans-serif",
                  display: 'flex',
                  'font-size': '24px',
                  'font-weight': '700',
                  'margin-bottom': '10px',
                },
                form_title: {
                  display: 'flex',
                  'font-size': '14px',
                  'line-height': '18px',
                  'font-weight': '400',
                  'margin-bottom': '20px',
                  'font-family': "'Poppins', sans-serif",
                },
                submit_button: {
                  color: 'white',
                  'background-color': '#000000',
                  'font-size': '16px',
                },
              }}
              formParams={{
                headerText: `Get your form for only $${PDF_PRICE}`,
                titleText:
                  'Your form is ready for download now. Once you pay, the download will start automatically. We will also send this form to your email address.',
                submitButtonText: 'Download my form',
              }}
              merchantData={payment}
              onOrderStatus={handleSuccess}
              onSubmit={handleSubmit}
              onFail={handleFail}
              onMounted={() => setPaymentModalStatus(PaymentModalStatuses.Opened)}
              onCustomStylesAppended={() => setPaymentModalRendered(true)}
            />
            {isPaymentModalRendered && (
              <div className={styles.paymentFormInfo}>
                By clicking Pay, I agree to the{' '}
                <Link to='/terms' className={styles.paymentFormLink}>
                  Terms of Use & Service
                </Link>{' '}
                and{' '}
                <Link to='/privacy-policy' className={styles.paymentFormLink}>
                  Privacy Policy
                </Link>{' '}
                and confirm that I am 18+ years old. The charge will appear on your card statement as{' '}
                <a className={styles.paymentFormLink} href='https://pdf-answer.com/'>
                  pdf-answer.com
                </a>{' '}
                Lemandale Limited, Gibraltar, Gibraltar.
              </div>
            )}
          </Modal>
        );
      case PaymentModalStatuses.Success:
        return (
          <StatusModal isSuccess={true} setPaymentModalStatus={setPaymentModalStatus} setPaymentData={setPaymentData} />
        );

      case PaymentModalStatuses.Failed:
        return (
          <StatusModal
            isSuccess={false}
            setPaymentModalStatus={setPaymentModalStatus}
            setPaymentData={setPaymentData}
          />
        );
      default:
        return null;
    }
  };

  const handleSuccess = async (event: OrderStatusMessage) => {
    if (event.response?.order?.status === 'approved') {
      trackConversion();
      addExportButton();
      setPaymentModalStatus(PaymentModalStatuses.Success);
      if (!instanceRef.current) return;
      const pdfArrayBuffer = await instanceRef.current.exportPDF();
      const blob = new Blob([pdfArrayBuffer], { type: 'application/pdf' });
      const file = new File([blob], `${fileName}.pdf`, { type: 'application/pdf' });
      const formData = new FormData();
      formData.append('pdf', file);
      formData.append('email', (event.response?.order as unknown as { customer_email: string }).customer_email);
      sendEmailWithPdf(formData);

      const downloadLink = document.createElement('a');
      const blobUrl = URL.createObjectURL(blob);
      downloadLink.href = blobUrl;
      downloadLink.download = `${fileName}.pdf`;
      downloadLink.click();
    }
  };

  const handleFail = () => {
    setPaymentModalStatus(PaymentModalStatuses.Failed);

    if (!id) {
      return;
    }

    trackGaEvent('payment_page_payment_click_fail', { form_name: id, type: 'card' });
  };

  useEffect(() => {
    if (!payment && userIP) {
      handlePayment(userIP);
    }
  }, [payment, userIP]);

  useEffect(() => {
    const container = containerRef.current;
    let instance: Instance;

    if (container) {
      (async function () {
        instance = await PSPDFKit.load({
          container,
          document: `pdf/${fileName}.pdf`,
          styleSheets: ['./pdfkit.css'],
          enableHistory: true,
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
          licenseKey: IS_PRODUCTION ? PSPDFKIT_LICESNE_KEY : '',
        });

        instance.addEventListener('annotations.focus', () => {
          trackAnnotationClick();
        });

        instance.setViewState((state) => state.set('allowPrinting', false));

        instanceRef.current = instance;

        const items = instance.toolbarItems;
        instance.setToolbarItems([
          ...items
            .slice(0, 10)
            .concat({ type: 'undo' }, { type: 'redo' }, items.slice(10))
            .filter(
              (item) =>
                item.type !== 'export-pdf' &&
                item.type !== 'print' &&
                item.type !== 'document-editor' &&
                item.type !== 'document-crop',
            ),
        ]);
      })();
    }
    return () => {
      PSPDFKit && PSPDFKit.unload(container);
    };
  }, []);

  return (
    <>
      <div ref={containerRef} style={{ width: '100%', height: 'calc(100vh - 73px)', marginTop: '72px' }} />
      {payment && renderModal()}
    </>
  );
};

export default PdfViewerComponent;
