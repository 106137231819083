import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PdfViewerComponent from './components/PdfViewerComponent/PdfViewerComponent';
import TermsPage from './components/TermsPage/TermsPage';
import PolicyPage from './components/PolicyPage/PolicyPage';
import ContactUs from './components/ContactUs/ContactUs';
import Overlay from './components/Overlay/Overlay';
import Header from './components/Header/Header';
import { PaymentModalStatuses } from '../src/utils/enums';
import { trackGaEvent } from './utils/trackGaEvent';

const App = () => {
  const location = useLocation();
  const id = location.pathname.substring(1);
  const [isEditorVisible, setEditorVisible] = useState(false);
  const [documentName, setDocumentName] = useState<string | undefined>(id);
  const [paymentModalStatus, setPaymentModalStatus] = useState(PaymentModalStatuses.Closed);

  const handleDownload = () => {
    setEditorVisible(true);

    if (!id) {
      return;
    }

    trackGaEvent('landing_get_my_form_click', { form_name: id });
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    if (paymentModalStatus === PaymentModalStatuses.Opened) {
      trackGaEvent('payment_page_view', { form_name: id });
    }
  }, [id, paymentModalStatus]);

  useEffect(() => {
    setDocumentName(id);
  }, []);

  return (
    <div className='App'>
      <Header
        title='Fill the PDF'
        buttonTitle='Contact Us'
        documentName={documentName}
        isEditorVisible={isEditorVisible}
        paymentModalStatus={paymentModalStatus}
        setPaymentModalStatus={setPaymentModalStatus}
      />
      <Routes>
        <Route
          path='/:id'
          element={
            isEditorVisible ? (
              <PdfViewerComponent
                paymentModalStatus={paymentModalStatus}
                setPaymentModalStatus={setPaymentModalStatus}
              />
            ) : (
              <Overlay handleDownload={handleDownload} />
            )
          }
        />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/terms' element={<TermsPage />} />
        <Route path='/privacy-policy' element={<PolicyPage />} />
        <Route path='*' element={<Navigate to='/W-9' replace />} />
      </Routes>
    </div>
  );
};

export default App;
