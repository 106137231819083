import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <p className={styles.footerText}>
        Your use of this site is subject to{' '}
        <Link to='/terms' className={styles.footerLink}>
          Terms of Use & Service{' '}
        </Link>
        and{' '}
        <Link to='/privacy-policy' className={styles.footerLink}>
          Privacy Policy
        </Link>
      </p>
    </div>
  );
};

export default Footer;
