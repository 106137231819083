import React from 'react';
import styles from './TermsPage.module.scss';

const TermsPage = () => {
  return (
    <div className={styles.container}>
      <h1>Terms of Use & Service</h1>

      <p>
        <span>
          These Terms of Use & Service (the “Terms” or the “Agreement”) are an agreement between You, as a user of the
          website askuora.com (“Website”), and Lemandale Limited with registered office at 5-9 Main Street, GX1 11AA,
          Gibraltar (“Askuora”, or “we”/“us”).
        </span>
      </p>

      <p>
        <span>
          These Terms govern your access to and use of the Website and the associated services offered through this
          Website.
        </span>
      </p>

      <p>
        <span>
          Browsing the Website, its use or viewing the information in it constitutes the acceptance of all the terms of
          the Agreement. In case you do not agree with any condition of the Agreement, immediately close the Website and
          stop any use of it.
        </span>
      </p>

      <p>
        <span>
          PLEASE NOTE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION PROVISION IN SECTION 12 THAT AFFECTS YOUR RIGHTS
          UNDER THIS AGREEMENT WITH RESPECT TO ALL SERVICE(S). THE ARBITRATION PROVISION REQUIRES THAT DISPUTES BE
          RESOLVED IN ARBITRATION ON AN INDIVIDUAL BASIS. IN ARBITRATION, THERE IS NO JUDGE OR JURY AND THERE IS LESS
          APPELLATE REVIEW THAN IN COURT.
        </span>
      </p>

      <p>
        <span>
          FURTHERMORE, THIS AGREEMENT CONTAINS DISCLAIMERS OF WARRANTIES, LIMITATIONS OF LIABILITY, AND A CLASS ACTION
          WAIVER.
        </span>
      </p>

      <p>
        <span>
          All the policies, which may be adopted or introduced by us from time to time, including but not limited to
          Privacy Policy and Cookie Policy constitute an integral part of this Agreement are incorporated into this
          Agreement by reference.
        </span>
      </p>

      <p>
        <span>
          We may change these Terms by posting them on this page of the Website. Changes shall automatically be
          effective upon posting. We may notify you specifically about some critical changes but are not obliged to do
          so in every case. Your use of the Website after any changes are made means that you accept such changes. After
          getting notice of changes of the Terms, if you do not object and opt-out of the amended Terms within fourteen
          (14) days, the amended version of the Terms is binding upon you.
        </span>
      </p>

      <div className={styles.listContainer}>
        <strong>
          <span>1. ASKUORA SERVICES</span>
        </strong>
      </div>

      <div className={styles.listContainer}>
        <span>1.1. How Askuora Services Work</span>
      </div>

      <p>
        <span>Askuora platform allows its Users to access and fill out various forms (“Content”). </span>
      </p>

      <p>
        <span>Askuora is not affiliated with IRS, or any other authority or government agency.</span>
      </p>

      <p>
        <span>
          Forms provided on Askuora are to be used by Users for general information purposes only, not as a substitute
          for in-person evaluation or specific professional (tax, financial, etc.) advice. Askuora does not provide
          advice or any professional service to Users. Askuora is not acting as an agent for any Customer. No
          professional-client relationships shall be formed on Askuora.
        </span>
      </p>

      <p>
        <span>
          Askuora does not warrant or guarantee that any Content is accurate, correct, complete, or reliable. Askuora
          does not hold any responsibility in this regard. Askuora shall not be liable for any loss or damage arising
          from your reliance on information or other Content posted on Askuora or transmitted to or by any User or third
          party.
        </span>
      </p>

      <div className={styles.listContainer}>
        <strong>
          <span>2. USER ACCOUNTS</span>
        </strong>
      </div>

      <div className={styles.listContainer}>
        <span>2.1 Eligibility</span>
      </div>
      <p>
        <span>
          To create an account on Askuora, you shall be at least 18 years old (or of the reciprocal age of majority in
          your state or jurisdiction) and able to form legally binding contracts. We do not knowingly collect personal
          information from children under 18 years of age.
        </span>
      </p>

      <div className={styles.listContainer}>
        <span>2.2 Registration</span>
      </div>

      <p>
        <span>
          In course of registration of an account, you need need to provide us a valid email address, username, password
          and/or other information as prompted by the registration form or as required by applicable law. We may also
          allow you to register by using your social network credentials.
        </span>
      </p>

      <div className={styles.listContainer}>
        <span>2.3 Account Security</span>
      </div>

      <p>
        <span>
          You are solely responsible for maintaining the confidentiality of your account, your password and for
          restricting access to your computer, and you will be solely responsible for all acts or omissions that occur
          under your account. You will immediately notify Askuora of any unauthorized use of your password or account.
          You should only create one account on the Website. If your Askuora account has been suspended or terminated,
          you may not open another account on the Website.
        </span>
      </p>

      <p>
        <span>
          Unless expressly permitted in writing by Askuora, you may not sell, rent, lease, share, or expressly access to
          your account to anyone else, including without limitation, charging anyone for access to your account. Askuora
          reserves all available legal rights and remedies to prevent its unauthorized use, including, but not limited
          to, technological barriers, IP mapping, and, in serious cases, directly contacting your Internet Service
          Provider (ISP) regarding such unauthorized use.
        </span>
      </p>

      <p>
        <span>
          You agree to keep your contact and billing information (including but not limited to email address) true,
          accurate, complete and up-to-date, and to comply with all billing procedures, including providing and
          maintaining accurate and lawful billing information for active Askuora accounts.
        </span>
      </p>

      <div className={styles.listContainer}>
        <strong>
          <span>3. REPRESENTATIONS AND WARRANTIES FROM AND RESTRICTED ACTIVITIES OF USERS</span>
        </strong>
      </div>
      <div className={styles.listContainer}>
        <span>3.1. Representations and Warranties from Users</span>
      </div>
      <p>
        <span>By using the Website, you represent and warrant that you</span>
      </p>
      <ol>
        <li>
          <span>(i) agree to be bound by the Terms,</span>
        </li>
        <li>
          <span>
            (ii) are over the age of eighteen (18) years old (or of the reciprocal age of majority in your state or
            jurisdiction),
          </span>
        </li>
        <li>
          <span>(iii) are neither located in a sanctioned country nor a prohibited person,</span>
        </li>
        <li>
          <span>
            (iv) have the right, authority, and capacity to enter into this Agreement and abide by all of the terms and
            conditions of this Agreement,
          </span>
        </li>
        <li>
          <span>
            (v) agree and acknowledge that by using the Website you are accepting a benefit that cannot be disgorged,
            and
          </span>
        </li>
        <li>
          <span>(vi) will not be engaged into any kind of activities that are prohibited.</span>
        </li>
      </ol>
      <div className={styles.listContainer}>
        <span>3.2. Prohibited Activities of Users</span>
      </div>
      <p>
        <span>This is a list of activities that we prohibit on the Website:</span>
      </p>
      <ol>
        <li>
          <span>
            a) You shall not remove any copyright, trademark, or other proprietary rights notices contained on the
            Website;
          </span>
        </li>
        <li>
          <span>
            b) You shall not interfere with or disrupt the Website or the servers or networks connected to the Website;
          </span>
        </li>
        <li>
          <span>
            c) You shall not forge headers or otherwise manipulate identifiers in order to disguise the origin of any
            information transmitted through the Website;
          </span>
        </li>
        <li>
          <span>
            d) You shall not &quot;frame&quot; or &quot;mirror&quot; any part of the Website, without Askuora prior
            written authorization. You also shall not use metatags or code or other devices containing any reference to
            Askuora or the ******* in order to direct any person to any other website for any purpose;
          </span>
        </li>
        <li>
          <span>
            e) You shall not modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile, or
            otherwise disassemble any portion of the Website or any software used on or for the Website or cause others
            to do so;
          </span>
        </li>
        <li>
          <span>
            f) You shall not attempt to interfere with, harm, steal from, or gain unauthorized access to the Website,
            User accounts, or the technology and equipment supporting the Website;
          </span>
        </li>
        <li>
          <span>
            g) You shall not use the Website in an unlawful or illegal way or commit an illegal act in relation to the
            Website including, without limitation, all laws associated with international money transfers;
          </span>
        </li>
        <li>
          <span>
            h) You shall not access or use (or attempt to access or use) the Website in any way that violates this
            Agreement;
          </span>
        </li>
        <li>
          <span>
            i) Your use of the Website shall not create liability for us or cause us to lose (in whole or in part) the
            services of our ISPs or other suppliers; and
          </span>
        </li>
        <li>
          <span>
            You may not resell or make any commercial use of our system or our content on the Website without
            Askuora&rsquo;s prior written consent.
          </span>
        </li>
      </ol>
      <p>
        <span>
          Although Askuora cannot monitor the conduct of its Users while not on the Website, it is also a violation of
          these Terms to use any information obtained from the Website in order to contact, advertise to, solicit, or
          sell to any User without their prior explicit consent or to harass, abuse, or harm another person.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>3.3 Our Rights Regarding Violations of Warranties and Restrictions by You</span>
      </div>

      <p>
        <span>
          In order to protect our Users from prohibited advertising or solicitation, we reserve the right to restrict
          the number of emails that a User may send to other Users in any 24-hour period to a number that we deem
          appropriate in our sole discretion.
        </span>
      </p>

      <p>
        <span>
          We may conduct an investigation regarding possible breaches by you of the requirements of these Terms. In
          particular, we reserve the right to ask for any additional documents and information necessary for
          investigation in accordance with our Privacy Policy.
        </span>
      </p>

      <p>
        <span>
          If such investigation shows or we become aware in any other way that there is a violation by you, we may
          deactivate your profile on the Website. In addition, we may take appropriate legal action against you due to
          such violation, without limitation, seeking civil, criminal, and injunctive redress.
        </span>
      </p>

      <div className={styles.listContainer}>
        <strong>
          <span>4. PAYMENTS AND REFUNDS</span>
        </strong>
      </div>
      <div className={styles.listContainer}>
        <span>4.1 Payment</span>
      </div>
      <p>
        <span>
          The access to Askuora platform is possible on a payment basis. The fees for Askuora services are stated on the
          Website. Please always check the current fees before payment.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>4.2 No Refunds</span>
      </div>
      <p>
        <span>All fees paid to Askuora are non-refundable. </span>
      </p>
      <p>
        <strong>
          <span>Notice for residents of certain US states. </span>
        </strong>
        <span>
          If you reside in California and canceled the Terms of Use, without penalty or obligation, at any time prior to
          midnight of the third business day following your registration on the Website, we will return, within ten days
          of the date on which you give notice of cancellation, any payments you have made.
        </span>
      </p>
      <p>
        <strong>
          <span>Notice for the EU residents. </span>
        </strong>
        <span>
          You hereby request immediate performance of the Terms of Use and acknowledge that you will lose your right of
          withdrawal from the Terms of Use once they are fully performed. Therefore, if you cancel the Terms of Use for
          any reason and without justification within 14 calendar days from the date of your registration on the
          Website, you are entitled to the proportionate refund (i.e., for unspent credits only).
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>4.3 Receipt of Special Offers and Other Communications</span>
      </div>
      <p>
        <span>
          By accepting these Terms, you agree to receive coupons, special offers, and other communications from Askuora
          per the terms of the Privacy Policy. You may modify this setting by making the relevant changes to your
          Askuora profile.
        </span>
      </p>
      <div className={styles.listContainer}>
        <strong>
          <span>5. USE OF CONTACTS AND INTERACTION WITH CUSTOMER SERVICE</span>
        </strong>
      </div>
      <div className={styles.listContainer}>
        <span>5.1 Electronic Communication</span>
      </div>
      <p>
        <span>
          Without limiting other provisions of this Agreement (including the Privacy Policy), by using the Website, you
          agree to accept and consent to receiving electronic communications initiated from us regarding any issue
          arisen from this Agreement and Website.
        </span>
      </p>
      <p>
        <span>
          All information communicated on the Website is considered electronic communication. When you communicate with
          us through or on the Website or via other forms of electronic media, such as e-mail, you are communicating
          with Askuora electronically.
        </span>
      </p>

      <p>
        <span>
          You agree that we may communicate electronically with you and that such communications, as well as notices,
          disclosures, agreements, and other communications that we provide to you electronically, are equivalent to
          communications in writing and shall have the same force and effect as if they were in writing and signed by
          the party sending the communication.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>5.2 Telephone Calls and Text Messages</span>
      </div>
      <p>
        <span>
          Communications from Askuora, including its agents, representatives, affiliates, or business partners, may
          include but are not limited to: operational communications concerning your account or use of the Askuora
          Website including account verification and message notifications, updates concerning new and existing features
          of the Website, marketing information and promotions run by us, our agents, representatives, affiliates, or
          business partners, any news concerning Askuora and any other purposes related to the Website or required to
          enforce these Terms.
        </span>
      </p>
      <p>
        <span>
          By entering your telephone number into the Askuora website, you expressly agree to receive communications at
          that number as required for the purposes identiﬁed above from us, our agents, representatives, afﬁliates, or
          business partners, via e-mail, SMS or text messages, phone calls, and push notiﬁcations. You further expressly
          agree that these communications, including phone calls, SMS or text messages, may be generated using automated
          technology, such as an automatic telephone dialing system, or artiﬁcial or prerecorded voice, and even if your
          telephone number(s) is listed on any Do-Not-Call lists. We are not responsible for the timeliness or final
          delivery of the communication, as we cannot control work of the cellular telephone operator or other networks.
        </span>
      </p>
      <p>
        <span>
          By agreeing to receive communications from Askuora, you also consent to the use of an electronic record to
          document your agreement. You may withdraw your consent to the use of the electronic record by emailing Askuora
          at pdfforms@askuora.com with “Revoke Electronic Consent” in the subject line. To view and retain a copy of
          this disclosure or any information regarding your enrollment in this program, you will need (i) a device (such
          as a computer or mobile phone) with a web browser and Internet access and (ii) either a printer or storage
          space on such device. For a free paper copy, or to update our records of your contact information, email
          Askuora at pdfforms@askuora.com with contact information and the address for delivery.
        </span>
      </p>
      <p>
        <span>
          We will use your mobile number in accordance with our Privacy Policy. You represent that for the telephone
          number(s) that you have provided to us, you are the current subscriber or customary user and that you have the
          authority to provide the consent described above to be contacted at such number(s). You agree to promptly
          alert us whenever you stop using a particular telephone number. Standard charges may apply to the receipt of
          these calls or text messages. You are responsible for those charges.
        </span>
      </p>
      <p>
        <span>
          You acknowledge that you are not required to consent to receive promotional texts or calls as a condition of
          using the Website or the services provided by non-Askuora entities. Please note that consent is not a
          condition of using our Website and consent may be revoked at any time. However, opting out may impact your use
          of the Website.
        </span>
      </p>
      <p>
        <span>
          We may monitor or record telephone conversations that we have with you or anyone acting on your behalf
          regardless you call us or we call you. We will use the results of monitoring and recording in accordance with
          our Privacy Policy.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>5.3. Prohibited Conduct towards Customer Service</span>
      </div>
      <p>
        <span>
          When communicating with our customer service representatives, you agree not to be abusive, obscene, profane,
          offensive, sexist, threatening, harassing, racially offensive, and behaving inappropriately anyhow.
        </span>
      </p>
      <p>
        <span>
          If your behavior towards any of our representatives is such as described above, we may terminate your
          membership.
        </span>
      </p>
      <div className={styles.listContainer}>
        <strong>
          <span>6. PRIVACY</span>
        </strong>
      </div>
      <p>
        <span>
          We respect your privacy and the use and protection of your personal information. Your submission of personal
          information through the Website is governed by our Privacy Policy. It contains important information and
          disclosures relating to the collection and use of your personal information by us.
        </span>
      </p>
      <div className={styles.listContainer}>
        <strong>
          <span>7. SUSPENSION OR TERMINATION OF MEMBERSHIP</span>
        </strong>
      </div>
      <div className={styles.listContainer}>
        <span>7.1. General Grounds for Termination of Account</span>
      </div>
      <p>
        <span>
          Customers may terminate their accounts at their sole discretion and at any time by written notice via e-mail
          to pdfforms@askuora.com. Terminations typically will be effective within seven business days after our receipt
          of your termination notice, at which time your account will be closed and you will no longer enjoy access to
          your former account. The procedure for Experts to terminate their accounts is set forth in the Expert
          Agreement.
        </span>
      </p>
      <p>
        <span>
          We may block or terminate your use of the Website and/or modify or discontinue any portion or all of the
          Website at any time, at our sole discretion, for any or no reason and without notice.
        </span>
      </p>
      <p>
        <span>For the purposes of these Terms:</span>
      </p>
      <p>
        <span>
          “Termination” means deletion of the account from the Askuora servers and complete erasure of all data related
          to a User&rsquo;s profile. At a User&rsquo;s request, we may retrieve all personal data and provide in a
          comprehensive and readable form.
        </span>
      </p>
      <p>
        <span>
          “Blocking” means temporary or permanent restriction of access to a User&rsquo;s Profile for violation of these
          Terms.
        </span>
      </p>
      <p>
        <span>
          The decision regarding termination or blocking of the account is made each time by the Askuora Customer
          Service at its sole discretion.
        </span>
      </p>
      <p>
        <span>
          In particular, termination of account is possible as a result of its inactivity for three months, violation of
          these Terms, security reasons, etc.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>7.2. Termination as a Result of Death or Disability</span>
      </div>
      <p>
        <span>
          If a person that was the User of the Website is no longer able to use the Website by reason of death or
          disability, such person or his/her legal representative or legal successor may contact us regarding
          termination of the account.
        </span>
      </p>
      <p>
        <span>
          ”Disability” means a condition which precludes you from physically using the Website during the term of
          disability and the condition is verified in writing by a physician designated and remunerated by you. Written
          verification from the physician must be submitted to Askuora.
        </span>
      </p>

      <p>
        <span>
          If the physician determines that the duration of the disability will be less than six (6) months, we may
          extend the term of the service contract for a period of six (6) months at no additional charge instead of
          terminating the account.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>7.3. Blocking of IP addresses</span>
      </div>
      <p>
        <span>
          In order to protect the integrity of the Website, Askuora reserves the right, at any time in its sole
          discretion, to block Users with certain IP addresses from accessing the Website.
        </span>
      </p>
      <p>
        <span>
          In particular, Askuora may block Users with IP addresses from certain jurisdictions, including, but not
          limited to, those that are subject to sanctions of the United Nations Security Council, included into the OFAC
          Sanctions List or the EU consolidated list of persons, groups and entities subject to financial sanctions.
        </span>
      </p>
      <div className={styles.listContainer}>
        <strong>
          <span>8. LICENSE AND COPYRIGHT POLICY</span>
        </strong>
      </div>
      <div className={styles.listContainer}>
        <span>8.1. Proprietary Rights</span>
      </div>
      <p>
        <span>
          Unless otherwise indicated, all content, information, and other materials on Askuora (excluding Content),
          including, without limitation, trademarks and logos, the visual interfaces, graphics, design, compilation,
          information, software, computer code (including source code or object code), services, text, pictures,
          information, data, sound files, other files, and the selection and arrangement thereof (collectively, the
          “Materials”) are protected by relevant intellectual property and proprietary rights and laws. All Materials
          are the property of Askuora or its subsidiaries or affiliated companies and/or third-party licensors. Unless
          otherwise expressly stated in writing by Askuora, by agreeing to these Terms you are granted a revocable,
          limited, non-exclusive, royalty-free, non-sublicensable, non-transferrable license to access and use Askuora.
        </span>
      </p>
      <p>
        <span>
          Askuora reserves all rights not expressly granted in these Terms. This license is subject to these Terms and
          does not permit you to engage in any of the following: (a) resale or commercial use of Askuora or the
          Materials; (b) distribution, public performance or public display of any Materials; (c) copying, modifying,
          reverse engineering, decompiling, disassembling, attempting to derive the source code of or otherwise making
          any derivative uses of Askuora or the Materials, or any portion of them; (d) use of any data mining, robots,
          or similar data gathering or extraction methods; (e) downloading (except page caching) of any portion of
          Askuora, the Materials, or any information contained in them, except as expressly permitted on Askuora; or (f)
          any use of Askuora or the Materials except for their intended purposes. Any use of Askuora or the Materials
          except as specifically authorized in these Terms, without the prior written permission of Askuora, is strictly
          prohibited and may violate intellectual property rights or other laws. Unless explicitly stated in these
          Terms, nothing in them shall be interpreted as conferring any license to intellectual property rights, whether
          by estoppel, implication, or other legal principles. Askuora can terminate this license as set out in Section
          7.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>8.2. License</span>
      </div>
      <p>
        <span>
          You grant to Askuora and its affiliates, licensees, and successors, to the furthest extent and for the maximum
          duration permitted by applicable law (including in perpetuity if permitted under applicable law), a
          non-exclusive, unrestricted, irrevocable, perpetual, worldwide, royalty-free, fully sublicensable (through
          multiple tiers) license to exercise the copyright, publicity, and database rights over the Content, including
          the right to use, reproduce, display, edit, copy, modify, transmit, publicly perform, extract and create
          derivative works of, in any media now known or not currently known, with respect to any Content.
        </span>
      </p>
      <p>
        <span>
          You agree that any Content you post on the Website, including any materials, ideas, comments and testimonials
          you submit on the Website, will not be considered confidential and may be used by Askuora, in its sole
          discretion, without any obligation to compensate for use of or to return any submitted materials.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>8.3. Our Actions in Cases of Intellectual Property Rights Infringement</span>
      </div>
      <p>
        <span>We do not tolerate infringing activity on or through the Website anyhow.</span>
      </p>
      <p>
        <span>
          If we have any reasons to believe in good faith that Content and/or any kind of information violates
          intellectual property rights of a third party by being made available on or through the Website, upon notice
          from an intellectual property owner or his or her agent, we:
        </span>
      </p>
      <p>
        <span>(i) remove or disable access to material; and</span>
      </p>
      <p>
        <span>(ii) remove any Content uploaded to the Website by &quot;repeat infringers&quot;. </span>
      </p>
      <p>
        <span>
          &quot;Repeat infringer&quot; is a User that has uploaded Content to or through the Website and about whom
          Askuora has received more than two takedown notices compliant under applicable law with respect to such User
          Content.
        </span>
      </p>
      <p>
        <span>
          However, we may terminate the account of a User after receipt of a single notification of claimed
          infringement.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>8.4. Procedure for Reporting Claimed Infringement</span>
      </div>
      <p>
        <span>
          If you believe that any content made available on or through the Website infringes your intellectual property
          right, please promptly send a written &quot;Notification of Claimed Infringement&quot; containing the
          following information to the designated agent identified below. Askuora may share your Notification of Claimed
          Infringement with the User that is a possible infringer, and you consent to making such disclosure by Askuora.
          Your communication must include substantially the following:
        </span>
      </p>
      <p>
        <span>
          1. A physical or electronic signature of a person authorized to act on behalf of the owner of the material(s)
          that has/have been allegedly infringed;
        </span>
      </p>
      <p>
        <span>
          2. Identification of the material allegedly being infringed, or, if multiple materials are covered by a single
          notification, then a representative list of such works;
        </span>
      </p>
      <p>
        <span>
          3. Identification of the specific material that is claimed to be infringing or to be the subject of infringing
          activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient
          to permit Askuora to locate the material on the Website;
        </span>
      </p>
      <p>
        <span>
          4. Information reasonably sufficient to permit Askuora to contact you, such as your name, address, telephone
          number, and email address;
        </span>
      </p>

      <p>
        <span>
          5. A statement that you have a good faith belief that use of the material in the manner complained of is not
          authorized by the copyright or other intellectual property owner, its agent, or the law; and
        </span>
      </p>
      <p>
        <span>
          6. Under penalty of perjury, a statement that the information in the Notification of Claimed Infringement is
          accurate and truthful, and that you are authorized to act on behalf of the owner of an exclusive right that is
          allegedly infringed.
        </span>
      </p>
      <p>
        <span>
          You should consult with your own lawyer and/or review applicable law regarding copyright or other intellectual
          property infringement to confirm your obligations to provide a valid notice of claimed infringement.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>8.5. Designated Agent Contact Information</span>
      </div>
      <p>
        <span>
          Designated agent of Askuora for receipt of Notifications of Claimed Infringement can be contacted at e-mail:
          pdfforms@askuora.com. .
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>8.6. False Notifications of Claimed Infringement or Counter Notifications</span>
      </div>
      <p>
        <span>
          Askuora reserves the right to seek damages from any party that submits a false notification in violation of
          the law, as provided for by applicable law.
        </span>
      </p>
      <div className={styles.listContainer}>
        <strong>
          <span>9. DISCLAIMER OF WARRANTIES </span>
        </strong>
      </div>
      <p>
        <span>
          Except where otherwise inapplicable or prohibited by law to the fullest extent permitted by law, Askuora
          services, software, and related documentation are provided “as is” and without any warranty of any kind either
          express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a
          particular purpose. No information, whether oral or written, obtained by you from us through this Website
          shall create any warranty, representation or guarantee not expressly stated in these Terms.
        </span>
      </p>
      <p>
        <span>
          Askuora expressly disclaims all warranties of any kind whether express or implied, including, any warranty
          that:
        </span>
      </p>
      <ol>
        <li>
          <span>i. the Website will meet your requirements,</span>
        </li>
        <li>
          <span>ii. the Website will be uninterrupted, timely, secure, or error-free</span>
        </li>
        <li>
          <span>
            iii. the Website and the server that makes it available are free of viruses or other harmful components
          </span>
        </li>
        <li>
          <span>
            iv. the results that may be obtained from the use of the Website, including data, will be, correct,
            accurate, timely, or reliable,
          </span>
        </li>
        <li>
          <span>v. the quality of any data or service available on the Website will meet your expectations, and</span>
        </li>
        <li>
          <span>vi. any defects or errors in the Website will be corrected.</span>
        </li>
      </ol>
      <p>
        <span>
          Any material obtained through the use of the Website is accessed at your own discretion and risk, and you will
          be solely responsible for any damage to your computer system or mobile device or loss of data that results
          from the use of any such material.
        </span>
      </p>
      <p>
        <span>
          We cannot guarantee and do no promise any specific results from use of the Website. You agree also to take the
          risks of interruption of the Website for any technical reasons.
        </span>
      </p>
      <div className={styles.listContainer}>
        <strong>
          <span>10. LIMITATION OF LIABILITY</span>
        </strong>
      </div>
      <p>
        <span>
          Except where otherwise inapplicable or prohibited by law in no event shall Askuora, its parents, subsidiaries,
          officers, directors, shareholders, employees, agents, joint venturers, consultants, successors or assigns be
          liable for any indirect, special, incidental, consequential, punitive or exemplary damages (including, but not
          limited to, loss of business, profits, data, use, revenue or other economic advantage), even if advised of the
          possibility of such damages resulting from or in connection with:
        </span>
      </p>
      <ol>
        <li>
          <span>a) the use, attempted use, or the inability to use the Website;</span>
        </li>
        <li>
          <span>
            b) reliance on information obtained through the Website, from other Users or third parties (such as the
            Experts or others) or a linked site, or User&rsquo;s reliance on any product or service obtained from a
            third party or a linked site;
          </span>
        </li>
        <li>
          <span>c) statements or conduct of any user or third party on the Website;</span>
        </li>
        <li>
          <span>d) unauthorized access to or alteration of your transmissions or data; or</span>
        </li>
        <li>
          <span>e) any other matter relating to the Website.</span>
        </li>
      </ol>
      <p>
        <span>
          The limitation of damages set forth above is a fundamental element of the basis of the bargain between us and
          you. This Website and the information would not be provided without such limitations. Even if Askuora is found
          liable under nay theory in no event will our liability, and the liability of our parents, subsidiaries,
          officers, directors, employees, and suppliers, to you or any third parties in any circumstance exceed the
          greater of (a) the amount of fees you pay to Askuora in the 12 months prior to the action giving rise to
          liability, or (b) USD 100.
        </span>
      </p>
      <div className={styles.listContainer}>
        <strong>
          <span>11. INDEMNIFICATION </span>
        </strong>
      </div>
      <p>
        <span>
          To the fullest extent permitted by law you agree to indemnify and hold Askuora, its parents, subsidiaries,
          officers, directors, shareholders, employees, agents, joint venturers, consultants, successors and assigns
          harmless from and against all losses, costs, liabilities and expenses including reasonable attorneys&rsquo;
          fees, asserted by any third-party resulting from or in any way connected with or related to your use of or
          conduct on the Website and/or your breach of this Agreement and/or any of your representations and warranties
          set forth above.
        </span>
      </p>
      <p>
        <span>
          Askuora reserves the right to assume the exclusive defense and control of any matter otherwise subject to
          indemnification by you, in which event you will fully cooperate with, and fully indemnify, Askuora in
          connection therewith.
        </span>
      </p>
      <div className={styles.listContainer}>
        <strong>
          <span>12. DISPUTE RESOLUTION BY MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER</span>
        </strong>
      </div>
      <p>
        <span>
          PLEASE READ THE FOLLOWING ARBITRATION AGREEMENT IN THIS SECTION 12 (&quot;ARBITRATION AGREEMENT&quot;)
          CAREFULLY. IT REQUIRES YOU TO ARBITRATE DISPUTES WITH ASKUORA AND LIMITS THE MANNER IN WHICH YOU CAN SEEK
          RELIEF FROM US.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>12.1. Applicability of Arbitration Agreement</span>
      </div>
      <p>
        <span>
          This Arbitration Agreement governs any dispute between you and Askuora (and each of our respective agents,
          corporate parents, subsidiaries, affiliates, predecessors in interest, successors, and assigns) including but
          not limited to claims arising out of or relating to any aspect of the relationship between you and Askuora,
          whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory; claims that
          arose before these Terms or any prior agreement; and claims that may arise after the termination of these
          Terms. However, (1) you may assert claims in small claims court if your claims qualify within the scope of
          your jurisdiction; and (2) you or Askuora may seek equitable relief in court for infringement or other misuse
          of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and
          patents). This Arbitration Agreement shall apply, without limitation, to all claims that arose or were
          asserted before the effective date of these Terms or any prior version of these Terms.
        </span>
      </p>
      <p>
        <span>
          The relevant arbitrator shall have sole authority to determine applicability of the Arbitration Agreement in
          each particular case. In the event that a dispute involves both issues that are subject to arbitration and
          issues that are not subject to arbitration, the parties unequivocally agree that any legal proceeding
          regarding the issues not subject to arbitration shall be stayed pending resolution of the issues subject to
          arbitration.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>12.2. Initial Dispute Resolution</span>
      </div>
      <p>
        <span>
          Most disputes can be resolved without resort to arbitration. If you have any dispute with Askuora, you agree
          that before taking any formal action, you will contact us at pdfforms@askuora.com, and provide a brief,
          written description of the dispute and your contact information. The parties agree to use their best efforts
          to settle any dispute, claim, question, or disagreement directly through consultation with Askuora, and good
          faith negotiations will be a condition to either party initiating an arbitration.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>12.3. Binding Arbitration</span>
      </div>
      <p>
        <span>
          If the parties do not reach an agreed-upon solution within a period of sixty (60) days from the time informal
          dispute resolution is initiated under the Initial Dispute Resolution provision above, then either party may
          initiate binding arbitration as the sole means to resolve claims subject to the terms set forth below.
          Specifically, all claims arising out of or relating to these Terms (including the Terms formation,
          performance, and breach), the parties relationship with each other, and/or your use of the Website will be
          finally settled by binding arbitration before one arbitrator administered by:
        </span>
      </p>
      <p>
        <span>
          (1) the London Court of International Arbitration (“LCIA”) if you are not a U.S. resident. Disputes are
          subject to the most current version of the LCIA Arbitration Rules when the notice of arbitration is submitted.
          Information about the LCIA&rsquo;s rules can be found at
          https://www.lcia.org/Dispute_Resolution_Services/LCIA_Arbitration.aspx; or
        </span>
      </p>
      <p>
        <span>
          (2) JAMS if you are a U.S. resident. Disputes involving claims and counterclaims under $250,000, not inclusive
          of attorneys&rsquo; fees and interest, shall be subject to JAMS&rsquo;s most current version of the
          Streamlined Arbitration Rules and procedures available at
          http://www.jamsadr.com/rules-streamlined-arbitration/; all other claims shall be subject to JAMS&rsquo;s most
          current version of the Comprehensive Arbitration Rules and Procedures, available at
          http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS&rsquo;s rules are also available at
          www.jamsadr.com or by calling JAMS at 800-352-5267.
        </span>
      </p>
      <p>
        <span>
          In each case the relevant arbitration rules will apply as modified by this Arbitration Agreement. In the event
          of a conflict between the applicable arbitration rules and these Terms, these Terms shall govern unless
          otherwise agreed by the parties and the relevant arbitrator.
        </span>
      </p>
      <p>
        <span>
          f the relevant administrator of arbitration is not available to arbitrate, the parties will select an
          alternative arbitral forum.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>12.4. Arbitration Proceedings</span>
      </div>
      <p>
        <span>Initiating Arbitration. To start an arbitration, you must follow instructions available at:</span>
      </p>
      <ol>
        <li>
          <span>
            (1) https://www.lcia.org/adr-services/lcia-notes-for-parties.aspx#5.%20COMMENCING%20AN%20LCIA%20ARBITRATION
            for LCIA; or
          </span>
        </li>
        <li>
          <span>(2) https://www.jamsadr.com/submit/ for JAMS.</span>
        </li>
      </ol>
      <p>
        <span>
          Arbitration Fees. If you are a consumer and you initiate arbitration, the only fee required to be paid is $250
          and the other part of the filing fee (if any) will be borne by us. If the arbitrator finds the arbitration
          initiated by you to be non-frivolous and/or not in bad faith, all other arbitration costs will be borne by
          Askuora. If Askuora initiates arbitration against you and you are a consumer, Askuora will pay for all costs
          associated with the arbitration. The parties are responsible for paying their own attorneys&rsquo; fees unless
          the arbitration rules and/or applicable law provide otherwise.
        </span>
      </p>
      <p>
        <span>
          Should either party bring a dispute involving issues subject to arbitration in a forum other than arbitration,
          the court or the arbitrator shall have the authority to award reasonable costs, fees and expenses, including
          reasonable attorneys&rsquo; fees, incurred by the other party in successfully staying or dismissing, in whole
          or in part, such other proceeding or in otherwise enforcing compliance with this Arbitration Agreement.
        </span>
      </p>
      <p>
        <span>
          Arbitrator Selection. The arbitrator must be neutral, and you will have a reasonable opportunity to
          participate in the process of choosing the arbitrator.
        </span>
      </p>
      <p>
        <span>
          Arbitration Hearings. The arbitrator will conduct hearings, if any, by teleconference or videoconference
          (based on written and/or electronic filing of documents), rather than by personal appearances, unless the
          arbitrator determines upon request by you or by us that an in-person hearing is appropriate. Any in-person
          appearances will be held at a location which is reasonably convenient to both parties with due consideration
          of their ability to travel and other pertinent circumstances, provided that if you are a consumer, you have a
          right to an in-person hearing in your hometown area. If the parties are unable to agree on a location, such
          determination should be made by the administrator of arbitration or by the arbitrator.
        </span>
      </p>
      <p>
        <span>
          Consumer Remedies. If you are a consumer, remedies that would otherwise be available to you under applicable
          laws will remain available under this Arbitration Agreement, unless you retain the right to pursue such
          remedies in court as per this Agreement.
        </span>
      </p>
      <p>
        <span>
          Discovery of Non-privileged Information. As part of the arbitration, both you and we will have the opportunity
          for discovery of non-privileged information that is relevant to the claim.
        </span>
      </p>
      <p>
        <span>
          Upon either party&rsquo;s request, the arbitrator will issue an order requiring that confidential information
          of either party disclosed during the arbitration (whether in documents or orally) may not be used or disclosed
          except in connection with the arbitration or a proceeding to enforce the arbitration award and that any
          permitted filing of confidential information must be done under seal.
        </span>
      </p>
      <p>
        <span>
          Communications with the Arbitrator. Whenever communicating with the arbitrator, the parties must include each
          other – for example, by including the other party on a telephone conference call and copying the other party
          on any written submissions, such as letters or emails. To the extent practicable, conferences with the
          arbitrator will take place by telephone conference call or email. Ex parte communications are not permitted
          with any arbitrator.
        </span>
      </p>
      <p>
        <strong>
          <span>Choice of Law. The arbitrator shall apply:</span>
        </strong>
      </p>
      <p>
        <span>
          1) if you are not a U.S. resident, the laws of England and Wales (also known as English Law), without regard
          to English Law&rsquo;s conflict of laws rules; or
        </span>
      </p>
      <p>
        <span>
          2) if you are a U.S. resident, Delaware law consistent with the Federal Arbitration Act and applicable
          statutes of limitations, and shall honor claims of privilege recognized at law.
        </span>
      </p>
      <p>
        <span>
          Arbitrator&rsquo;s Award. An arbitrator&rsquo;s award will consist of a written statement stating the
          disposition of each claim. The award will also provide a concise written statement of the essential findings
          and conclusions on which the award is based.
        </span>
      </p>
      <p>
        <span>
          The arbitration award shall be final and binding on the parties. Judgment on the arbitrator&rsquo;s award may
          be entered in any court of competent jurisdiction.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>12.5. Class Action Waiver and Jury Trial Waiver</span>
      </div>
      <p>
        <span>
          THE ARBITRATOR HAS NO AUTHORITY TO AWARD PUNITIVE DAMAGES. NEITHER YOU NOR ASKUORA AGREES TO ANY ARBITRATION
          ON A CLASS BASIS, AND THE ARBITRATOR SHALL HAVE NO AUTHORITY TO PROCEED ON SUCH A BASIS. A PARTY MAY ASSERT A
          CLAIM OR COUNTERCLAIM ONLY IN THAT PARTY&rsquo;S INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
          ANY PURPORTED CLASS PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON&rsquo;S CLAIMS AND MAY
          NOT OTHERWISE PRESIDE OVER ANY FORM OF CLASS PROCEEDING. UNDER THE ARBITRATION AGREEMENT, ARBITRATOR SHALL NOT
          COMBINE OR CONSOLIDATE MORE THAN ONE PARTIES CLAIMS WITHOUT THE WRITTEN CONSENT OF ALL AFFECTED PARTIES TO AN
          ARBITRATION PROCEEDING.
        </span>
      </p>
      <p>
        <span>
          BY AGREEING TO THE ARBITRATION OF DISPUTES AS SET FORTH HEREIN, YOU AGREE THAT YOU ARE WAIVING YOUR RIGHT TO A
          JURY TRIAL AND LIMITING YOUR RIGHT TO APPEAL AND YOU UNDERSTAND THAT YOU ARE WAIVING YOUR RIGHTS TO OTHER
          AVAILABLE RESOLUTION PROCESSES, SUCH AS A COURT ACTION.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>12.6. Litigation of Intellectual Property and Small Claims Court Claims</span>
      </div>
      <p>
        <span>
          Notwithstanding the parties&apos; decision to resolve all disputes through arbitration, either party may bring
          enforcement actions, validity determinations or claims arising from or relating to theft, piracy or
          unauthorized use of intellectual property in court with jurisdiction or in other relevant state authority to
          protect its intellectual property rights. Either party may also seek relief in a small claims court for
          disputes or claims within the scope of that court&apos;s jurisdiction.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>12.7. 30-Day Right to Opt Out</span>
      </div>
      <p>
        <span>
          You have the right to opt out and not be bound by the arbitration and class action waiver provisions set forth
          above by sending electronic notice of your decision to opt out to pdfforms@askuora.com with the subject line,
          &quot;ARBITRATION AND CLASS ACTION WAIVER OPT-OUT.&quot; The notice must be sent within 30 days of (a) the
          effective date of these Terms; or (b) your first date that you used the Website that contained any versions of
          the Terms that substantially included this version of the Arbitration Agreement (including class action
          waiver), whichever is later. Otherwise, you will be bound to arbitrate disputes in accordance with the terms
          of these paragraphs. If you opt out of this Arbitration Agreement, Askuora also will not be bound by it.
        </span>
      </p>
      <p>
        <span>
          In order to be effective, the opt out notice must include your full name and clearly indicate your intent to
          opt out of binding arbitration. By opting out of binding arbitration, you are agreeing to resolve disputes in
          accordance with Section 16.1 &quot;Governing Law and Venue.&quot;
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>12.8. Severability of Arbitration Agreement</span>
      </div>
      <p>
        <span>
          If any portion of this Arbitration Agreement is found to be unenforceable or unlawful for any reason, (a) the
          unenforceable or unlawful provision shall be severed from these Terms; (b) severance of the unenforceable or
          unlawful provision shall have no impact whatsoever on the remainder of this Arbitration Agreement or the
          parties&rsquo; ability to compel arbitration of any remaining claims on an individual basis pursuant to this
          Arbitration Agreement; and (c) to the extent that any claims must therefore proceed on a class, collective,
          consolidated, or representative basis, such claims must be litigated in court in accordance with Section 16.1
          &quot;Governing Law and Venue,&quot; and the parties agree that litigation of those claims shall be stayed
          pending the outcome of any individual claims in arbitration. Further, if any part of this Arbitration
          Agreement is found to prohibit an individual claim seeking public injunctive relief, that provision will have
          no effect to the extent such relief is allowed to be sought out of arbitration, and the remainder of this
          Arbitration Agreement will be enforceable.
        </span>
      </p>
      <div className={styles.listContainer}>
        <span>12.9. Survival</span>
      </div>
      <p>
        <span>This Arbitration Agreement will survive any termination of your use of the Website.</span>
      </p>
      <div className={styles.listContainer}>
        <strong>
          <span>13. CHANGES TO THE AGREEMENT AND ITS PARTIES. NOTICES</span>
        </strong>
      </div>
      <div className={styles.listContainer}>
        <span>13.1. Changes to the Agreement</span>
      </div>
      <p>
        <span>
          We reserve the right, at our sole discretion, to change the Agreement from time to time and at any time and
          without prior notice by
        </span>
      </p>
      <p>
        <span>a) posting the changed Agreement (or parts of it) to the Website; or</span>
      </p>
      <p>
        <span>b) otherwise giving you notice of the changes.</span>
      </p>
      <p>
        <span>
          The changes shall be effective upon such posting by us or upon us giving you such notice, whichever is the
          earlier (unless we expressly indicate otherwise).
        </span>
      </p>
      <p>
        <span>
          It is your responsibility to check the Website and your email account periodically for changes to these Terms
          and other parts of the Agreement.
        </span>
      </p>
      <p>
        <span>
          Your continued use of or access to the Website following the effective date of any changes to the Agreement
          constitutes acceptance of those changes. This Agreement may not be changed by you, unless any changes proposed
          by you are expressly accepted by Askuora in writing
        </span>
      </p>

      <p>
        <span>Any new features which are added to the Website shall also be subject to the Terms.</span>
      </p>

      <p>
        <span>
          In this clause, the terms &quot;change&quot; and &quot;changed&quot; in relation to changes to the Agreement
          shall be interpreted broadly and shall include any and all modifications, amendments, revisions and
          restatements whatsoever, including adding or removing any portions of this Agreement.
        </span>
      </p>

      <div className={styles.listContainer}>
        <span>13.2. Changes to Parties</span>
      </div>

      <p>
        <span>
          We may assign or transfer all of our rights and obligations hereunder to any other person, whether by way of
          novation or otherwise, and you hereby give us consent to any such assignment and transfer.
        </span>
      </p>

      <p>
        <span>
          You agree that posting on this Website of a version of this Agreement indicating another person as a party to
          this Agreement will constitute sufficient notice to you of the transfer of our rights and obligations under
          the Agreement with you to that party (unless otherwise is expressly indicated).
        </span>
      </p>

      <div className={styles.listContainer}>
        <span>13.3. Notices</span>
      </div>

      <p>
        <span>
          Without prejudice to the provisions of the preceding clause, we may choose to notify you of changes to this
          Agreement by posting a notice via the Website, by sending you an email, or otherwise.
        </span>
      </p>

      <p>
        <span>
          If we choose to notify you about changes to this Agreement or about other matters by email, each such
          notification shall be effective and shall be deemed received by you immediately after being sent to the email
          address you have provided to us, even if:
        </span>
      </p>

      <ol>
        <li>
          <span>
            (a) our email notification is filtered as a spam, junk, bulk, or other undesirable or low-priority message
            and is not displayed in your email inbox; or
          </span>
        </li>
        <li>
          <span>(b) you do not actually read it for any other reason.</span>
        </li>
      </ol>

      <p>
        <span>
          To reduce the chance that it is so filtered, please add pdfforms@askuora.com to your email contact book and
          whitelist this address as a &quot;safe&quot; or &quot;approved&quot; sender. In addition, you may wish to
          create a custom filter marking emails from this address as important emails for your high-priority inbox.
          Please contact your email service provider if you are not sure how to do any of that.
        </span>
      </p>

      <div className={styles.listContainer}>
        <strong>
          <span>14. TERM OF THE AGREEMENT</span>
        </strong>
      </div>

      <p>
        <span>
          This Agreement will take full force and effect when you access the Website and will remain in effect while you
          use the Website until your account is terminated for whatever reason.
        </span>
      </p>

      <p>
        <span>
          After your membership is terminated, all terms that by their nature may survive termination of this Agreement
          shall be deemed to survive such termination including, but not limited to, Sections 3, 5-17.
        </span>
      </p>

      <p>
        <span>
          We reserve the right to take further action for our loss or the potential loss of other Users or third parties
          when necessary due to your breach of this Agreement, in our sole discretion.
        </span>
      </p>

      <div className={styles.listContainer}>
        <strong>
          <span>15. ELECTRONIC SIGNATURE</span>
        </strong>
      </div>

      <p>
        <span>
          You further acknowledge and agree that by clicking on a button labeled &quot;SUBMIT&quot;, &quot;I
          ACCEPT&quot;, &quot;I AGREE&quot; or similar links or buttons, you are submitting a legally binding electronic
          signature and are entering into a legally binding contract. You acknowledge that your electronic submissions
          constitute your agreement and intent to be bound by this Agreement.
        </span>
      </p>

      <p>
        <span>
          Pursuant to any applicable statutes, regulations, rules, ordinances or other laws, including without
          limitation the United States Electronic Signatures in Global and National Commerce Act, P.L. 106-229 (the
          &quot;E-Sign Act&quot;) or other similar statutes, you hereby agree to the use of electronic signatures,
          contracts, orders and other records and to electronic delivery of notices, policies and records of
          transactions initiated or completed through the Website.
        </span>
      </p>

      <p>
        <span>
          Furthermore, you hereby waive any rights or requirements under any statutes, regulations, rules, ordinances or
          other laws in any jurisdiction which require an original signature, delivery or retention of non-electronic
          records, or to payments by other than electronic means.
        </span>
      </p>

      <div className={styles.listContainer}>
        <strong>
          <span>16. MISCELLANEOUS</span>
        </strong>
      </div>

      <div className={styles.listContainer}>
        <span>16.1. Governing Law and Venue</span>
      </div>

      <p>
        <span>
          Except as otherwise specified herein, this Agreement shall be governed by and construed in accordance with the
          law of Gibraltar.
        </span>
      </p>

      <p>
        <span>
          To the extent that any action relating to any dispute hereunder is for whatever reason not submitted to
          arbitration, each of the parties submits to the exclusive jurisdiction to the courts of England and Wales to
          settle any disputes which may arise out of or in connection with this Agreement and that accordingly the
          relevant proceedings must be brought in such courts.
        </span>
      </p>

      <p>
        <span>
          The parties irrevocably submit to the personal jurisdiction and venue of the courts of England and waive any
          defenses of improper venue or forum non conveniens.
        </span>
      </p>

      <div className={styles.listContainer}>
        <span>16.2. Entire Agreement. Severability</span>
      </div>

      <p>
        <span>
          This Agreement and all other provisions referenced herein contain the entire agreement between you and Askuora
          regarding the use of the Website. If any provision of this Agreement is held invalid, the remainder of this
          Agreement shall continue in full force and effect.
        </span>
      </p>

      <div className={styles.listContainer}>
        <span>16.3. No Waiver of Breach or Default</span>
      </div>

      <p>
        <span>
          The failure to require performance of any provision will not affect our right to require performance at any
          time thereafter, nor will a waiver of any breach or default of this Agreement or any provision of this
          Agreement constitute a waiver of any subsequent breach or default or a waiver of the provision itself.
        </span>
      </p>

      <div className={styles.listContainer}>
        <span>16.4. Force Majeure</span>
      </div>

      <p>
        <span>
          Askuora shall not be responsible for any failure to perform due to unforeseen circumstances or to causes
          beyond our reasonable control, including but not limited to: acts of God, such as fire, flood, earthquakes,
          hurricanes, tropical storms or other natural disasters; war, riot, arson, embargoes, acts of civil or military
          authority, or terrorism; strikes, or shortages in transportation, facilities, fuel, energy, labor or
          materials; failure of the telecommunications or information services infrastructure; hacking, spam, or any
          failure of a computer, server or software, for so long as such event continues to delay Askuora’ performance.
        </span>
      </p>

      <div className={styles.listContainer}>
        <span>16.5. Absence of Certain Kind of Relationship</span>
      </div>

      <p>
        <span>
          You agree that no joint venture, partnership, employment, or agency relationship exists between you and
          Askuora as a result of this Agreement or use of the Website.
        </span>
      </p>

      <div className={styles.listContainer}>
        <span>16.6. Use of Section Headers</span>
      </div>

      <p>
        <span>
          Use of Section headers in this Agreement is for convenience only and will not have any impact on the
          interpretation of particular provisions.
        </span>
      </p>

      <div className={styles.listContainer}>
        <strong>
          <span>17. CONTACT INFORMATION</span>
        </strong>
      </div>

      <p>
        <span>Please Contact Us with any questions regarding this Agreement.</span>
      </p>

      <p>
        <span>E-mail: pdfforms@askuora.com</span>
      </p>

      <p>
        <span>Attention of: Customer Support, Askuora.</span>
      </p>

      <p>
        <span>All Rights Reserved.</span>
      </p>

      <p>
        <span>Customer Support: pdfforms@askuora.com</span>
      </p>
    </div>
  );
};

export default TermsPage;
