import React from 'react';
import styles from './modal.module.scss';

type ModalProps = {
  children: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({ children }) => {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalContent}>{children}</div>
    </div>
  );
};

export default Modal;
